<template>
  <div>
    <div>
      <div class="search-geo-list">
        <div class="close-icon" @click="close">&times;</div>
        <b-input-group class="" v-if="searchKey !== 'allocation'">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input v-model="filter" debounce="800" placeholder="Search" />
        </b-input-group>
        <div class="table-responsive" v-if="showLoading">
          <b-skeleton-table
            :rows="4"
            :columns="2"
            :table-props="{ bordered: true, striped: true }"
          />
        </div>
        <div
          v-if="!showLoading"
          class="search-geo-outer"
          ref="searchCardDynamicHeight"
          @scroll="handleScroll"
        >
          <b-list-group>
            <b-list-group-item
              v-for="item in items"
              :key="item.id"
              class="d-flex justify-content-between align-items-center"
            >
              <div class="check-outer">
                <b-form-checkbox
                  v-model="item.selected"
                  @change="clickSelectedGeoFence(item, $event)"
                >
                  <div class="d-flex align-items-center">
                    <div class="poly-outer" v-if="searchKey === 'geofence'">
                      <PolygonImg :style="{ fill: item.color }" />
                    </div>
                    <div class="mr-75 marginLeftImg unit-url">
                      <b-avatar
                        v-if="searchKey === 'unit'"
                        size="35"
                        :src="item.icon"
                      />
                    </div>

                    <span>{{ item.name }}</span>
                  </div>
                </b-form-checkbox>
              </div>
            </b-list-group-item>
          </b-list-group>
        </div>
        <h6 class="text-left mtt-5" v-if="searchKey === 'allocation'">
          {{ $t("device.ReallocateTo") }} :
        </h6>
        <div
          class="d-flex justify-content-center mb-1"
          v-if="searchKey === 'allocation'"
        >
          <AccountSearch
            v-if="searchKey === 'allocation'"
            v-model="allocatedAccount"
            :all="true"
            :placeholderName="$t('device.OwnerAccount')"
            :accountsL="accountsList"
            :isDisableConsumer="true"
            :isDisableQuery="true"
            @input="updateAccountUrl"
          />
        </div>
        <div
          class="d-flex justify-content-center mb-1 mtt-50"
          v-if="searchKey === 'group' || searchKey === 'allocation'"
        >
          <b-button
            :disabled="!allocatedAccount"
            class="ml-2"
            v-if="searchKey === 'allocation'"
            variant="primary"
            @click="save"
            >{{ $t("sim.Allocate") }}</b-button
          >
          <b-button
            v-if="searchKey !== 'allocation'"
            class="ml-2"
            variant="primary"
            @click="save"
            >{{ $t("sim.Update") }}</b-button
          >
          <b-button
            variant="outline-primary"
            class="ml-2 mr-2"
            @click="close"
            >{{ $t("sim.Cancel") }}</b-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BListGroup,
  BListGroupItem,
  BPagination,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BFormCheckbox,
  BAvatar,
  BSkeletonTable,
  BButton
} from "bootstrap-vue";
import PolygonImg from "@/assets/images/polygon.svg";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import UtilityService from "@/libs/api/utility-service";
import UnitService from "@/libs/api/unit-service";
import AccountService from "@/libs/api/account-service";
import AccountSearch from "@/views/Unit/Unit-V2/AccountSearch.vue";
export default {
  components: {
    BListGroup,
    BListGroupItem,
    BPagination,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    PolygonImg,
    BFormCheckbox,
    UtilityService,
    BAvatar,
    BSkeletonTable,
    BButton,
    AccountSearch
  },
  props: ["searchKey", "geofenceControl"],
  mounted() {
    if (this.searchKey === "geofence") {
      this.items = [];
      this.total = 0;
      this.getGeoFences();
    } else if (this.searchKey === "unit") {
      this.items = [];
      this.total = 0;
      this.getUnits();
    } else if (this.searchKey === "group") {
      this.items = [];
      this.total = 0;
      this.getGroups();
    } else if (this.searchKey === "allocation") {
      this.items = [];
      this.total = 0;
      this.getChildAccounts();
    }
  },
  watch: {
    searchKey(val) {
      if (val === "geofence") {
        this.items = [];
        this.total = 0;
        this.getGeoFences();
        this.currentPage = 1;
        this.page_size = 5;
      } else if (val === "unit") {
        this.items = [];
        this.total = 0;
        this.getUnits();
      } else if (this.searchKey === "group") {
        this.items = [];
        this.total = 0;
        this.getGroups();
      } else if (this.searchKey === "allocation") {
        this.items = [];
        this.total = 0;
        this.getChildAccounts();
      } else {
        this.items = [];
        this.total = 0;
        this.currentPage = 1;
        this.page_size = 5;
      }
    },
    "$store.state.route.route_check_point": {
      handler(val) {
        const data = val.filter((e) => e.type === this.searchKey);
        this.items.forEach((element) => {
          const item = data.find((e) => e.data.id === element.id);
          if (item) {
            element.selected = true;
          } else {
            element.selected = false;
          }
        });
      },
      deep: true
    },

    filter() {
      this.currentPage = 1;
      this.page_size = 5;
      if (this.searchKey === "geofence") {
        this.getGeoFences();
      } else if (this.searchKey === "unit") {
        this.getUnits();
      } else if (this.searchKey === "group") {
        this.getGroups();
      } else if (this.searchKey === "allocation") {
        this.getChildAccounts();
      }
    }
  },
  data() {
    return {
      filter: "",
      currentPage: 1,
      page_size: 5,
      total: 0,
      items: [],
      showLoading: false,
      accountsList: [],
      allocatedAccount: ""
    };
  },
  methods: {
    save() {
      if (this.searchKey === "group") {
        const item = this.items.filter((e) => e.selected).map((e) => e.name);
        this.$emit("selectedLabel", item);
      } else if (this.searchKey === "allocation") {
        this.$emit("allocatedAccount", this.allocatedAccount);
      }
    },
    close() {
      this.$emit("searchCardClose", true);
    },
    updateAccountUrl() {},
    handleScroll() {
      // Clear the previous timer
      this.debounceTimer = setTimeout(() => {
        const element = this.$refs.searchCardDynamicHeight;
        if (element) {
          if (
            element.scrollTop + element.clientHeight >= element.scrollHeight &&
            !this.showLoading
          ) {
            if (this.total > this.currentPage * this.page_size) {
              const me = this;
              this.$nextTick(() => {
                me.currentPage++;
                if (this.searchKey === "geofence") {
                  this.getGeoFences(true);
                } else if (this.searchKey === "unit") {
                  this.getUnits(true);
                } else if (this.searchKey === "group") {
                  this.getGroups(true);
                } else if (this.searchKey === "allocation") {
                  this.getChildAccounts(true);
                }
              });
            }
          }
        }
      }, 200); // Adjust the debounce time as needed
    },
    async getUnits(isScroll) {
      try {
        if (!isScroll) {
          this.showLoading = true;
        }
        const filters = this.filter
          ? [
              {
                field: "name",
                value: this.filter,
                operator: "ilike"
              }
            ]
          : [];

        let requestData = {
          page: parseInt(this.currentPage),
          page_size: this.page_size,
          account_id: localStorage.getItem("USER_ACCOUNT_ID"),
          filters
        };

        let response = await new UnitService().getUnitListV2(requestData);
        this.showLoading = false;
        if (response && response.data) {
          const unitItem = response.data.list || [];
          unitItem.forEach((element) => {
            element.selected = false;
          });
          this.items = isScroll ? [...this.items, ...unitItem] : unitItem;
          const data = this.$store.state.route.route_check_point.filter(
            (e) => e.type === this.searchKey
          );
          this.items.forEach((element) => {
            const item = data.find((e) => e.data.id === element.id);
            if (item) {
              element.selected = true;
            } else {
              element.selected = false;
            }
          });
          this.total = response.data.pagination.total_records;
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
          this.showLoading = false;
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    async getGeoFences(isScroll) {
      try {
        if (!isScroll) {
          this.showLoading = true;
        }

        const me = this;
        const requestData = {
          page: parseInt(this.currentPage),
          page_size: this.page_size
        };

        requestData.filters = this.filter
          ? [
              {
                field: "name",
                value: this.filter,
                operator: "ilike"
              }
            ]
          : [];

        let response = await new UtilityService().getAllGeoFences(requestData);
        this.showLoading = false;
        if (response && response.data) {
          const geoFenceItem = response.data.list || [];
          geoFenceItem.forEach((element) => {
            element.selected = false;
          });
          this.items = isScroll
            ? [...this.items, ...geoFenceItem]
            : geoFenceItem;
          const data = this.$store.state.route.route_check_point.filter(
            (e) => e.type === this.searchKey
          );
          this.items.forEach((element) => {
            const item = data.find((e) => e.data.id === element.id);
            if (item) {
              element.selected = true;
            } else {
              element.selected = false;
            }
          });
          this.total = response.data.pagination.total_records;
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        this.showLoading = false;
      }
    },
    async getGroups(isScroll) {
      try {
        if (!isScroll) {
          this.showLoading = true;
        }

        const me = this;
        const requestData = {
          page: parseInt(this.currentPage),
          page_size: this.page_size
        };

        requestData.filters = this.filter
          ? [
              {
                field: "all",
                value: this.filter,
                operator: "ilike"
              }
            ]
          : [];

        let response = await new UtilityService().getAllGroups(requestData);
        this.showLoading = false;
        if (response && response.data) {
          const groupItem = response.data.list || [];

          this.items = isScroll ? [...this.items, ...groupItem] : groupItem;

          this.total = response.data.pagination.total_records;
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        this.showLoading = false;
      }
    },
    clickSelectedGeoFence(item, flag) {
      let boundary = {
        type: "",
        coordinates: []
      };
      console.log(item);
      if (
        this.searchKey === "unit" &&
        item.position &&
        item.position.latitude &&
        item.position.longitude
      ) {
        boundary = {
          type: "marker",
          coordinates: [item.position.latitude, item.position.longitude]
        };
        const data = {
          data: {
            id: item.id,
            name: item.name,
            icon: item.icon,
            boundary,
            angle: item.position.angle
          },
          isSelected: flag,
          selectedType: this.searchKey
        };
        this.geofenceControl.events.fire("clickSelectedGeoFence", data);
      } else if (
        this.searchKey === "geofence" &&
        item.boundary &&
        item.boundary.type &&
        ((item.boundary.coordinates && item.boundary.coordinates.length) ||
          (item.boundary.coordinates &&
            item.boundary.coordinates.lat &&
            item.boundary.coordinates.lng &&
            item.boundary.radius))
      ) {
        const result = {
          data: {
            id: item.id,
            name: item.name,
            color: item.color,
            radius: item.boundary.radius,
            boundary: item.boundary
          },
          isSelected: flag,
          selectedType: this.searchKey
        };
        this.geofenceControl.events.fire("clickSelectedGeoFence", result);
      }
    },
    async getChildAccounts(filterAccount) {
      try {
        let response = await new AccountService().getChildAccounts({
          isChild: true,
          filterAccount: filterAccount || "",
          page: 1,
          page_size: 100
        });

        if (response && response.data) {
          function recursive(data) {
            if (data && data.type.toUpperCase() == "CONSUMER") return true;

            if (data && data.children) {
              for (let i = 0; i < data.children.length; i++) {
                if (recursive(data.children[i])) {
                  // delete data.children[i];
                  data.children[i]["isDisabled"] = true;
                }
              }
            }
            return false;
          }

          recursive(response.data[0]);

          this.accountsList = response.data;
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    }
  },
  beforeDestroy() {
    this.items = [];
    this.total = 0;
  }
};
</script>
<style lang="scss" scoped>
.search-geo-list {
  background: #fff;
  position: fixed;
  top: 30vh;
  border-radius: 4px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
  padding: 10px;
  width: 357px;

  z-index: 9999;
}
.search-geo-outer {
  padding: 0px;
  overflow-y: auto;
  max-height: 25vh;
  margin-top: 10px;
  .list-group-item {
    border: none;
    border-bottom: 1px solid rgba(34, 41, 47, 0.125);
  }
  .list-group-item:last-child {
    border-bottom: none;
  }
  .icon-block {
    display: flex;
  }
  .poly-outer {
    height: 35px;
    width: 35px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    -webkit-box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.18);
    -moz-box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.18);
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.18);
    margin-left: 10px;
    margin-right: 10px;
  }
  .mrl {
    cursor: pointer;
    margin-left: 7px;
    margin-right: 7px;
  }
}
html body .input-group:not(.bootstrap-touchspin):focus-within .form-control,
html
  body
  .input-group:not(.bootstrap-touchspin):focus-within
  .input-group-text {
  border-color: var(--primary) !important;
}
.dark-layout {
  .search-geo-list {
    background: var(--dark-bg);
  }
}
</style>
<style lang="scss">
.check-outer {
  .custom-checkbox {
    .custom-control-label {
      cursor: pointer;
    }
    .custom-control-label::before,
    .custom-control-label::after {
      top: 23%;
      bottom: 23%;
    }
  }
}
.unit-url {
  .b-avatar {
    .b-avatar-img {
      padding: 4px;
      img {
        object-fit: contain;
      }
    }
  }
}
.close-icon {
  background: #fff;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--dark46);
  position: relative;
  margin-top: -20px;
  float: right;
  margin-right: -26px;
  background-color: var(--white) !important;
  box-shadow: 0px 0px 15px 0px rgba(34, 41, 47, 0.05);
  border-radius: 6px;
  font-size: 2rem;
  font-weight: 400;
  cursor: pointer;
}
.dark-layout .close-icon {
  color: var(--white);
  background-color: var(--dark46) !important;
}
@media screen and (min-width: 991px) {
  .search-geo-list {
    right: 440px !important;
    width: 357px !important;
  }
}
@media screen and (max-width: 990px) {
  .search-geo-list {
    width: 90% !important;
  }
}
</style>
